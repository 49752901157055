<template>
  <div class="relative isolate overflow-hidden pb-16">
    <svg
        aria-hidden="true"
        class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]">
      <defs>
        <pattern id="0787a7c5-978c-4f66-83c7-11c213f99cb7" height="200" patternUnits="userSpaceOnUse" width="200"
                 x="50%"
                 y="-1">
          <path d="M.5 200V.5H200" fill="none"/>
        </pattern>
      </defs>
      <rect fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" height="100%" stroke-width="0" width="100%"/>
    </svg>
    <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
        <div class="mt-24 sm:mt-32 lg:mt-16">
          <div class="inline-flex space-x-6" href="#">
            <span
                class="rounded-full text-xs bg-indigo-600/10 px-3 py-1 font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
              What's new
            </span>

            <div class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
              <span>
                White-label your own domain for all links
              </span>
            </div>
          </div>
        </div>

        <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Supercharge your Flipboard & Social Media Growth
        </h1>

        <p class="mt-6 text-lg leading-8 text-gray-600">
          Create custom website links for Flipboard and social media with optimized headlines, images and hashtags.
        </p>

        <div class="mt-10 flex items-center gap-x-6">
          <nuxt-link class="btn-primary-md"
                     to="/register">
            Get started
          </nuxt-link>

          <nuxt-link class="text-sm font-semibold leading-6 text-gray-900" to="/login">
            Login <span aria-hidden="true">→</span>
          </nuxt-link>
        </div>
      </div>
      <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
          <div
              class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
            <img alt="App screenshot" class="w-[60rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                 height="1442" src="/app-screenshot.png"
                 width="2432">
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-hidden bg-gray-100 py-20 my-16" id="how-it-works">
      <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div class="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 class="text-base font-semibold leading-7 text-indigo-600">
                How It Works
              </h2>

              <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900">
                It's hard to repeatedly go viral on social media because social platforms cache content and deprioritize duplicates.
              </p>

              <p class="mt-6 leading-8 text-gray-600">
                Post Polish let's you repeatedly publish your best content on social media by creating brand new links to your best content as if the post was new.
              </p>

              <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                    </svg>
                    Fully customize the headline.
                  </dt>

                  <dd class="inline">
                    Feature an image and hashtags each time to make it pop without changing the source post.
                  </dd>
                </div>

                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                    </svg>
                    Schedule the post.
                  </dt>

                  <dd class="inline">
                    Go live when your followers are active
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div class="sm:px-6 lg:px-0 w-full">
            <div class="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
              <div class="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white" aria-hidden="true"></div>
              <div class="mx-auto max-w-2xl sm:mx-0">
                <img src="/how-it-works.png" width="2432" height="1442" class="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10">
              </div>
              <div class="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl" aria-hidden="true"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="info" id="pricing" class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <h1 class="text-base font-semibold leading-7 text-indigo-600">
          Pricing
        </h1>

        <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Pricing plans for publishers of all sizes
        </p>
      </div>

      <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Choose an affordable plan that’s packed with the best features for engaging your audience and creating customer
        loyalty.
      </p>

      <div v-if="info?.plans.length"
           class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
        <template v-for="plan in info.plans">
          <div class="rounded-3xl p-8 xl:p-10 ring-1 ring-gray-200">
            <div class="flex items-center justify-between gap-x-4">
              <h3 class="label-base font-semibold">
                {{ plan.name }}
              </h3>
            </div>

            <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-2xl font-bold tracking-tight text-gray-900">
              {{ plan.price_formatted }}
            </span>
              <span class="text-sm font-semibold leading-6 text-gray-600">/month</span>
            </p>

            <nuxt-link
                class="block mt-6 btn-outline-primary-md text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 w-full"
                to="/register">
              Subscribe
            </nuxt-link>

            <ul class="mt-8 space-y-3 label-sm text-gray-600 xl:mt-10">
              <li class="flex gap-x-3">
                Links per month:

                <span class="font-extrabold">
                  {{ plan.limits.urls }}
                </span>
              </li>


              <li class="flex gap-x-3">
                RSS feeds:

                <span class="font-extrabold">
                  {{ plan.limits.rss_feeds === -1 ? '∞' : plan.limits.rss_feeds }}
                </span>
              </li>

              <li class="flex gap-x-3">
                Domains:

                <span class="font-extrabold">
                  {{ plan.limits.domains }}
                </span>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
definePageMeta({
  layout: 'default',
  middleware: 'guest',
})

const route = useRoute()

const isLoadingInfo = ref(true)
const info = ref(null)

onMounted(() => {
  if (route.query._ptxn) {
    window.location.href = '/billing?_ptxn=' + route.query._ptxn
  } else {
    loadInfo()
  }
})

function loadInfo() {
  isLoadingInfo.value = true

  useApi('/info')
      .then(response => {
        info.value = response.data
      })
      .catch(() => {
        isLoadingInfo.value = false
      })
}
</script>
